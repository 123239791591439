/*
Theme Name: FLG Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for FLG Partners
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
body > #container {
     flex: 1 0 auto; /* 2 */
  width: 100%;
  position:relative;
  
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    background:#fff url(images/bg.png) repeat 0 0;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
    &.page-template-homepage.php{
        
    }
    iframe{
    	max-width:100%!important;
    	}
}



.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }

.tiled{
	background:url(images/blue-tile.png) repeat 0 0;
	}

#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
#content{
    width:@mainWidth;
	padding:50px 0 0px 0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}
	
#content-wrap{
   
    }

#main-content{
	padding:0 35px 0 0;
    line-height:1.7;
    color:@content;
    &.right{
    	padding:0 0 0 35px;
    	border-left:2px solid @grayLight;
    	}
    .right-side{
    	padding-left:30px!important;
    	}
    .left-side{
    	padding-right:30px!important;
    	border-right:2px solid @grayLight;
    	}
    h2{
    	.cinzel(40px);
    	margin:10px 0 20px 0;
    	line-height:1.2;
    	color:@blueDark;
    	}
    h3{
    	.opensans(28px);
    	color:@yellow;
    	margin:10px 0 20px 0;
    	font-weight:normal!important;
    	&.date{font-size:20px;}
    	}
    h4{
    	.cinzel(22px);
    	margin:10px 0 20px 0;
    	line-height:1.2;
    	color:@blueDark;
    	a{font-weight:400;}
    	}
    
    ul{
        padding:10px 0 10px 25px;
        li{
            position:relative;
            i.fa-caret-right{
            	position:absolute;
            	top:4px;
            	left:0;
            	color:@yellow;
            	font-size:18px;
            	}
            padding:0 0 0 30px;
            margin-bottom:8px;
            
            }
        }
    ul.inline{
        li{
            display:inline-block;
            width:30%;
            }
        }
    img{
        border:4px solid @grayLight;
        max-width:100%;
        height:auto;
        &.no-border{
        	border:none!important;
        	}
        }
    ol{
	counter-reset: my-counter;
	padding-left:0!important;
    >li{
        position:relative;
        padding-left:40px!important;
        margin-bottom:20px;
        padding-top:5px;
        h2,h3,h4{margin-top:0!important;line-height:1!important;}
        &:before {
	content: counter(my-counter);
	counter-increment: my-counter;
	width:30px!important;
	height:30px!important;
	line-height:1!important;
	display:block;
	background:@blueDark;
	color:#fff;
	.centered();
	.rounded-corners(50%);
    line-height:28px!important;
	position:absolute;
	.cinzel(12px);
	.bold();
	top:0;
	left:0;
}
        }
}
	a{
		color:@blueDark;
		.bold();
		&:hover{
			color:@yellow;
			}
		}
    }
	
#sidebar{
	padding:0 0 0 35px;
	border-left:2px solid @grayLight;
	}

ul.details{
	li{
		color:@blue;
		.bold();
		i{
			color:@yellow;
			margin-right:5px;
			}
		}
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @defaultBG;
            .transition(all linear 130ms);
            font-size:20px;
            .text-shadow();
            &:hover{
                background:#f1f1f1;
                padding-left:15px;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#footer {
	position: relative;
	width:@mainWidth;
	background:@defaultBG url(images/footer-bg.png) repeat-x 0 0;
	clear:both;
	.footer-logo{
		.inline-block();
		width:90%;
		max-width:420px;
		}
	.left,.right{padding:25px;position:relative;}
	.gform_wrapper, #searchform{
		padding:0!important;
		margin:0px!important;
		::-webkit-input-placeholder {
                       color: #fff;
                    }
        :-moz-placeholder { /* Firefox 18- */
                       color: #fff;  
                    }
        ::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff;  
                    }
        :-ms-input-placeholder {  
                       color: #fff;  
                    }
		label{
			display:none;
			}
		input[type=text]{
			width:100%;
			background:transparent;
			border:2px solid #fff;
			.cinzel(20px);
			color:#fff;
			outline:none;
			padding:8px 38px 8px 8px;
			.transition();
			&:focus{
				background:rgba(255,255,255,0.1);
				}
			}
		ul.gform_fields{
			padding:0!important;
			margin:0px!important;
			li{
				padding:0!important;
				margin:0px!important;
				&.gfield_error{
					max-width:100%!important;
					}
			}
			}
		button.search-submit{
			color:@yellow;
				background:transparent;
				border:none;
				font-size:24px;
				.scale(1);
				.transition();
			
			position:absolute;
			right:30px;
			top:30px;
				&:hover{
					.scale(1.1);
					cursor:pointer;
					}
			}
		.gform_footer{
			padding:0!important;
			margin:0px!important;
			width:auto!important;
			position:absolute;
			right:30px;
			top:30px;
			.inline-block();
			button{
				color:@yellow;
				background:transparent;
				border:none;
				font-size:24px;
				.scale(1);
				.transition();
				&:hover{
					.scale(1.1);
					cursor:pointer;
					}
				}
			}
		.ginput_container{
			padding:0!important;
			margin:0px!important;
			}
		}
	.social-menu{
		padding:20px 0 20px 0;
		li{
			font-size:45px;
			}
		}
	} 
#copyright{
	background:@blueDark;
	color:#fff;
	.cinzel(10px);
	.uppercase();
	padding:10px;
	i.fa-circle{
		font-size:2px;
		.inline-block();
		vertical-align:middle;
		margin:0 8px 0 8px;
		}
	a{
		color:#fff;
		&:hover{
			text-decoration:underline;
			}
		}
	}
	
.footer-content{
	text-align:center;
	padding:0;}
	
.section{
	clear:both;
    width:100%;
    position:relative;
    min-height:200px;
    padding:80px 0 80px 0;
    .bodyfont();
    color:@content;
    line-height:1.4;
    &.gray-bg{
    	background: @grayLight;
    	}
    }
.no-pad{
    	padding:0!important;
    	} 
h5.section-head{
	color:@blueDark;
	.cinzel(45px);
	margin-bottom:30px;
	line-height:1.2;
	&.white{
		color:#fff;
		}
	&.small{
		font-size:32px;
		}
	}
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
    
/**********HOMEPAGE**********/
#video-banner{
	overflow:hidden!important;
	position:relative;
	height:100vh;
		
	.overlay{
        position:absolute;
        display:block;
        width:100%;
        z-index:10;
        height:100%;
        top:0;
        left:0;
        background:rgba(7, 42, 67, 0.6);
        }
        
video#banner-video { 
opacity:0;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: @grayLight;
    background-size: cover; 
}
	}

#banner-slide-wrap{
	height:100vh;
	position:relative;
	z-index:200;
	
	}

.video-banner-item{
	position:relative;
	height:100vh!important;
	.centered();
	color:#fff;
	font-size:20px;
	>div{
		position:relative;
		top:50%;
	z-index:200;
	transform:translateY(-50%);
	p{
    		padding:0 20% 18px 20%;
    		}
		}
	h1{
		.cinzel(50px);
		color:#fff;
		padding-bottom:50px;
		margin-bottom:50px;
		position:relative;
		line-height:1.2;
		span{
			display:block;
			font-size:115px;
			}
		&:after{
			content:'';
			width:20%;
			height:2px;
			background:@yellow;
			position:absolute;
			left:40%;
			bottom:0;
			}
		}
	}

#banner{
    border-bottom:1px solid #fff;
    position:relative;
	padding:180px 0 80px 0;
	.overlay{
		background:rgba(7, 42, 67, 0.6);
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:2;
		}
	h1{
		color:#fff;
		.text-shadow();
		.centered();
		.cinzel(46px);
		position:relative;
		z-index:10;
		}
    }
    
ul.slick-dots{
    width:100%;
    .centered();
    position:absolute;
    bottom:-50px;
    z-index:50;
    li{
        .inline-block();
        padding:0;
        margin:0 3px 0 3px;
        button{
            text-align:left;
            background:#c6c6c7;
            width:10px;
            height:10px;
            border:none;
            padding:0!important;
            outline:none;
            display:block;
            .rounded-corners(50%);
            .transition(all linear 150ms);
            text-align:left;
            text-indent:-9999px;
            &:hover{
                cursor:pointer;
                background:@yellow;
                }
            }
        &.slick-active{
            button{
                background:@yellow!important;
                }
            }
        }
    }
    
.banner-content{
    height:100%;
    >div{
        
        height:100%;
        background:rgba(255,255,255,0.7);
        padding:20px;
        position:relative;
    	
        .bold();
        color:@accent;
        >div{
            position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
        }
    h5{
        .uppercase();
        .bold();
        color:@accent;
        line-height:1!important;
        margin-bottom:20px;
        }
    
    }
a.block{
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:900;
	}

#about-section{
	padding:90px 0 90px 0;
	.icon{
		.inline-block();
		width:150px;
		height:150px;
		.rounded-corners(50%);
		.centered();
		padding:10px;
		border:8px solid rgba(255,255,255,0.2);
		position:absolute;
		z-index:500;
		top:-75px;
		left:50%;
		margin-left:-75px;
		>div{
			.inline-block();
			width:100%;
			
			padding:10px;
			.rounded-corners(50%);
			.centered();
			background:#fff;
			img{width:100%!important;height:auto;display:block;}
			}
		}
	}

#solutions-section{
	>.solution-item{
		background:@grayLight;
		.centered();
		.transition();
		padding:50px 30px 50px 30px;
		position:relative;
		border-top:1px solid #fff;
		border-bottom:1px solid #fff;
		min-height:350px;
		&:nth-child(3n+2){
			border-left:1px solid #fff;
			border-right:1px solid #fff;
			}
		.icon{
			margin-bottom:30px;
			svg{
				overflow:visible;
				.inline-block();
				width:130px;
				height:130px;
				.circle{
					.transition();
					.scale(1);
					transform-origin: 50% 50%;
					-webkit-transform-origin: 50% 50%;
					}
				}
			}
		h6{
			.cinzel(18px);
			color:@blueDark;
			padding:0 30px 0 30px;
			}
		&:hover{
			background:@blueDark;
			.icon{
				svg{
					path, polygon, rect, circle, ellipse{fill:#fff;}
					.circle{
						.scale(1.1);
						}
					.hoverstate{
						fill:@yellow;
						}
					}
				}
			h6{color:#fff;}
			}
		}
	}

a.video-button{
	position:relative;
	display:block;
	width:100%;
	.centered();
	outline:none;
	i{
		font-size:80px;
		color:rgba(255,255,255,0.3);
		.inline-block();
		width:100%;
		.centered();
		position:absolute;
		.scale(1);
		.transition();
		top:35%;
		z-index:200;
	
		}
	img{
		border:none!important;
		&.bg{
			width:100%;
			height:auto;
			.inline-block();
			}
		&.video-button-image{
			width:60.5%;
			height:auto;
			position:absolute;
			left:20%;
			top:6.5%;
			}
		}
	&:hover{
		i{
			color:rgba(255,255,255,0.9);
			.scale(1.2);
			}
		}
	}

#why-section{
	padding:80px 0 80px 0;
	.left{
		border-left:5px solid @yellow;
		padding:15px 0px 15px 35px;
		}
	.right{
		padding-top:40px;
		}
	}

#sectors-section{
	color:#fff;
	padding:80px 0 0px 0;
	}

.overlay{
	position:absolute;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.3);
	top:0;
	left:0;
	z-index:10;
	}

.sector-items-wrap{
	margin-top:80px;
	border-top:1px solid #fff;
		border-bottom:1px solid #fff;
	>.sector-item{
		min-height:270px;
		padding:0;
			
		
		.overlay{
			.transition();
			}
		&:nth-child(3n-7){
			border-right:1px solid #fff;
			border-left:1px solid #fff;
			}
		&:nth-child(-n+3) {
			   border-bottom:1px solid #fff;
			}
	
		.title{
			color:#fff;
			width:100%;
			height:auto;
			padding:20px 0 20px 0;
			.cinzel(28px);
			.centered();
			position:absolute;
			top:41%;
			.text{
				position:relative;
				z-index:100;
				}
			.bg{
				display:block;
				background:rgba(246, 198, 48, 0.8);
				.transition();
				width:0;
				height:100%;
				position:absolute;
				top:0;
				left:0;
				z-index:10;
				}
			}
		&:hover{
			.overlay{
				background:rgba(0,0,0,0.6);
				}
			.title{
				.bg{width:100%;}
				}
			}
		}
	
	}

#clients-section{
	color:#fff;
	padding:0;
	.clients-content-wrap{
		padding:80px 0 120px 0;
		position:relative;
		.triangle{
			&.left{
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 60px 0 0 1100px;
				border-color: transparent transparent transparent #ffffff;
				position:absolute;
				bottom:0;
				left:0;
				}
			&.right{
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 60px 1100px;
				border-color: transparent transparent #ffffff transparent;
				position:absolute;
				bottom:0;
				right:0;
				}
			}
		}
	
	}
#clients-gallery-wrap{
		margin-top:0px;
		background:#fff;
		padding:100px 0 50px 0;
		position:relative;
		overflow:hidden;
		&.gray{
			padding:50px 0 50px 0;
			}
		&.dark{
			background:transparent;
			padding:5px 0 25px 0;
			}
		
		.client-logo-item{
			padding:0 30px 0 30px;
			.centered();
			
			
			img{
				max-width:80%;
				height:auto;
				.inline-block()!important;
				}
			&.page{
				height:150px!important;
				line-height:150px;
				img{
					vertical-align:middle!important;
					max-width:70%;
					}
				}
			}
		button{
			background:transparent;
			color:@yellow;
			border:none;
			font-size:32px;
			.transition();
			.scale(1);
			outline:none;
			&.slick-prev{
				position:absolute;
				top:50%;
				left:10px;
				}
			&.slick-next{
				position:absolute;
				top:50%;
				right:10px;
				}
			&:hover{
				.scale(1.1);
				cursor:pointer;
				}
			}
		}

#testimonials-section{
	color:#fff;
	padding:80px 0 80px 0;
	.left{
		.centered();
		padding:10% 50px 0 50px;
		}
	.right{
		border-left:3px solid @yellow;
		padding:40px 0 40px 50px;
		.italic();
		.text-shadow();
		a{
			color:@yellow;
			}
		cite{
			display:block;
			.opensans(15px);
			color:#fff;
			.uppercase();
			.bold();
			padding:10px 0 30px 0;
			font-style:normal;
			letter-spacing:.04em;
			}
		}
	}

#blog-section{
	padding:80px 0 80px 0;
	background:@grayLight;
	overflow:hidden;
	border-bottom:2px solid #fff;
	.central{
		z-index:5;
		}
	.watermark{
		height:90%;
		width:auto;
		position:absolute;
		right:-330px;
		top:5%;
		}
	}
	
.blog-item{
	padding:0 20px 0 20px;
	.item-content-wrap{
		background:#fff;
		border:1px solid #fff;
		.image{
			height:150px;
			}
		.item-content{
			padding:20px;
			h6{
				.cinzel(24px);
				color:@blueDark;
				margin-bottom:20px;
				}
			a.readmore{
				display:none;
				}
			a.button{
				margin-top:15px;
				}
			
			}
		.tags{
			font-size:14px;
			margin-top:20px;
			span{
				.inline-block();
				vertical-align:middle;
				.cinzel(16px);
				font-style:normal;
				padding:4px;
				}
			div{padding:4px;
				.inline-block();
				vertical-align:middle;
				}
			}
		}
	&.subpage{
		.item-content{
			background:@grayLight;
		}
		padding:0;
		margin-bottom:30px;
		}
	}

#twitter-section{
	padding:80px 0 80px 0;
	background:#fff;
	overflow:hidden;
	.central{
		z-index:5;
		}
	.watermark{
		font-size:500px;
		position:absolute;
		color:@grayLight;
		left:-100px;
		top:-190px;
		}
	a{
		.bold();
		color:@blueDark;
		&:hover{
			color:@yellow;
			}
		}
	}
	
/**********ABOUT PAGE**********/

#icon-section{
	margin-top:40px;
	padding:80px 0 80px 0;
	background:@blue;
	color:#fff;
	h5{font-size:32px;opacity:0;}
	}

.large-icon-wrap{
	span.separator{
		clear:both!important;
		width:100%;
		display:block;
		height:3px;
		background:#22577c;
		
		}
	.icon-item{
		padding:30px 10px 30px 10px;
		opacity:0;
		.icon{
			margin-bottom:15px;
			svg{
				.inline-block();
				width:100%;
				max-width:160px;
				.hoverstate, .hoverstate path, .hoverstate polygon,.hoverstate circle, .hoverstate ellipse {
					fill:@yellow;
					}
				}
			}
		span.stat{
			display:block;
			color:@yellow;
			.uppercase();
			.opensans(65px);
			.bold();
			}
		span.detail{
			display:block;
			color:#fff;
			.italic();
			}
		}
	}

#about-sectors{
	padding:0;
	.title-bar{
		background:#fff;
		border-bottom:10px solid @blue;
		padding:40px 0 40px 0;
		h5{margin-bottom:0;}
		}
	.sector-items-wrap{margin-top:0;}
	}
.action-section{
	padding:80px 0 80px 0;
	border-top:2px solid #fff;
	h5{color:#fff;}
	&.white{
		h5{color:@blueDark;}
		}
	}
	
#about-expertise{
	background:#ededee;
	
	}

.column-section{
	padding:80px 0 80px 0;
	ul{
		 -webkit-column-count: 3; /* Chrome, Safari, Opera */
        -moz-column-count: 3; /* Firefox */
        column-count: 3;
        li{
        	margin-bottom:10px;
        	position:relative;
        	padding:0 15px 0 24px;
        	i{
        		position:absolute;
        		font-size:18px;
        		top:2px;
        		left:0;
        		color:@yellow
        		}
        	}
        &.inline{
        		 -webkit-column-count: 1; /* Chrome, Safari, Opera */
		        -moz-column-count: 1; /* Firefox */
		        column-count: 1;
        	li{
        		.inline-block();
        		margin:10px;
        		}
        	}
		}
}
	
/**********CONTACT PAGE*********/

.top-content{
	.centered();
	color:@blueDark;
	.bold();
	.opensans(18px);
	.separator{
		.inline-block();
		width:10%;
		background:@yellow;
		margin:50px 0 50px 0;
		height:2px;
		}
	}
#map-wrap{
	height:auto;
	overflow:hidden;
	&.active{
		height:auto;
		}
	}

.acf-map{
	&.page{
		width:100%;
		height:400px;
		border:5px solid @grayLight;
		}
	&.wide{
		width:100%;
		height:400px;
		}
	&.clients{
		height:540px;
		}
	}
	
/**********WHAT WE DO********/

#engagement-options{
	padding:80px 0 30px 0;
	.option-item{
		.inline-block();
		text-align:left;
		width:100%;
		max-width:430px;
		background:url(images/svg/checkmark.svg) no-repeat top left;
		background-size:40px auto;
		padding-left:60px;
		margin:0 20px 40px 20px;
		h6{
			.opensans(22px);
			.uppercase();
			.bold();
			color:@blueDark;
			}
		}
	}
	
#fundraising{
	padding:80px 0 80px 0;
	ul{
		 -webkit-column-count: 3; /* Chrome, Safari, Opera */
        -moz-column-count: 3; /* Firefox */
        column-count: 3;
		li{}
		}
	}

.case-study-link{
	.inline-block();
	border:1px solid @yellow;
	margin:15px;
	width:240px;
	height:240px;
	background-size:50% auto!important;
	.centered();
	position:relative;
	border:1px solid @grayLight;
	.left{
		img{
		max-width:60%;
		height:auto;
		}
	}
	
	.fader{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background:rgba(255,255,255,0.9);
		color:#fff;
		padding:10px;
		.opacity(0);
		.transition(all linear 200ms);
		>div{
			top:50%;
			transform:translateY(-50%);
			position:relative;
			a.button{
				background:@blueDark!important;
				color:#fff!important;
				}
			}
		h6{
		.cinzel(16px);
		margin-bottom:15px;
		color:@blueDark;
		}
		}
		&:hover{
			.fader{
				.opacity(1);
				}
			}
	}	

#featured-partner{
	h6{
		.cinzel(24px);
		color:@blue;
		margin-bottom:15px;
		}
	.left{
		border-left:4px solid @yellow;
		padding-left:25px;
		}
	.right{
		padding-left:20px;
		}
	.image{
		img{
			max-width:80%;
			height:auto;
			border:1px solid @grayLight;
			}
		}
	}
	
#hiddendiv{
	position:absolute;
	width:100vw;
	left:-9999px;
	&.centerwidth{
		width:100%;
		max-width:@centralWidth;
		}
	}
	
/**********WHY FLG*******/

#accordion-section{
	background:@grayLight;
	padding:80px 0 80px 0;
	}
	
/*********CLIENTS********/

#clients-wrap{
	min-height:0;
	.centered();
	h4{
		color:@blue;
		.cinzel(24px);
		}
	&.page{
		padding:50px 0 50px 0;
		}
	}
	
.client-item{
	.inline-block();
	width:180px;
	height:180px;
	border:2px solid @grayLight;
	margin:10px;
	position:relative;
	.centered();
	line-height:180px;
	overflow:hidden;
	background-size:80% auto!important;
	a.block{
		z-index: 6000;
		}
	img{
		display:inline-block;
		vertical-align:middle;
		width:90%;
		height:auto;
		}
	.fader{
		line-height:1.2;
		position:absolute;
		width:100%;
		height:100%;
		background:rgba(9, 64, 103, 0.9);
		color:#fff;
		opacity:0;
		z-index:5000;
		>div{
			position:relative;
			padding:10px;
			top:50%;
			transform:translateY(-50%);
			span{
				padding:3px 0 3px 0;
				display:block;
				&.title{
					.opensans(14px);
					.uppercase();
					margin-bottom:3px;
					}
				&.term{
					.opensans(13px);
					.italic();
					}
				}
			}
		}
		&.alignleft{
			margin-top:0!important;
			margin-left:0!important;
			}
	}


.filter-item{
	margin:10px 0 10px 0;
	position:relative;
	input[type=text]{
		width:100%;
		border:1px solid @blueDark;
		.cinzel(18px);
		color:@blueDark;
		padding:10px 120px 10px 10px;
		}
	a.button-small{
		.opensans(11px);
		.bold();
		.uppercase();
		.inline-block();
		color:#fff;
		background:@blueDark;
		.rounded-corners(8px);
		.scale(1);
		.transition();
		padding:8px;
		position:absolute;
		top:8px;
		right:20px;
		&:hover{
			.scale(1.05);
			cursor: pointer;
			color:@yellow;
			}
		}
	}

.filter-menu-wrap{
	text-align: left;
	position: relative;

	
	a.filter-menu-toggle{
		position:relative;
		display:block;
		.cinzel(18px);
		padding:9px 10px 9px 10px;
		color:@blueDark;
		border:1px solid @blue;
		&:hover{
			cursor:pointer;
			}
		i{
			.inline-block();
			position:absolute;
			right:15px;
			top:15px;
			color:@yellow;
			}
		}
	.filter-menu{
		background:@grayLight;
		width:100%;
		height:0;
		position: absolute;
		z-index:9000;
		top:45px;
		left:0;
		
	
		overflow:hidden;
		&.active{
			overflow:visible;
			}
		>li{
			display:block;
			border-bottom:1px solid @grayLight;
			background:@blue;
			color:#fff;
			.cinzel(18px);
			position:relative;
			padding:10px;
			&.reset{
				background:#b5b5b5;
				color:@blueDark;
				}
			
				padding-right:20px;
				i{
					position:absolute;
					right:15px;
					top:10px;
					color:@yellow;
					font-size:22px;
					}
	
			>ul.children{
			display: none;
				width:50%;
				position:absolute;
				left:100%;
				top:0;
				background:@blueMed;
				z-index:8000;
				li{
					color:#fff;
					padding:10px;
					border-bottom:1px solid @grayLight;
					&:hover{
						cursor:pointer;
						background:@grayLight;
						color:@blueDark;
						}
					}
				
				}
			&:hover{
				cursor:pointer;
				background:#b5b5b5;
			
				}
			}
		
		
		
		}
}



#testimonials-slider{
	background:@grayLight;
	#testimonials-slider-wrap{
		padding:40px 0 40px 0;
		}
	}

.testimonial-slider-item{
		.italic();
		color:@content;
		outline:none;
		line-height:1.6;
		a{
			color:@blue;
			&:hover{color:@yellow;}
			}
		.right{
			text-align:left!important;
			}
		cite{
			display:block;
			.opensans(17px);
			color:@blue;
			.uppercase();
			padding:10px 0 30px 0;
			font-style:normal;
			letter-spacing:.04em;
			}
		img.testimonial-logo{
			max-width:240px!important;
			border:none!important;
			margin-top:20px;
			.inline-block();
			}
		.testimonial-full{
			width:100%;
			max-width:800px!important;
			.inline-block();
			}
	
	&.single{
		span.quote{
				line-height:1;
				vertical-align:top;
				.cinzel(100px);
				color:@blueDark;
				&.open{
					position:absolute;
					top:0;
					left:-8px;
					}
				&.close{
					position:absolute;
					top:0;
					right:-8px;
					}
				}
		.testimonial-full{
			padding:0 30px 0 60px;
			position:relative;
			}
		.right{
			position:relative;
			padding:0px 30px 0 60px;
			
			}
		}
	&.page{
		border-bottom:2px solid @grayLight;
		padding:30px;
		margin-bottom:30px;
		.testimonial-full{
			max-width:1100px!important;
			}
		}
	}

table.meta_table{
	width:100%;
	border-collapse: collapse;
	tr{
		&:nth-child(even){
			background:#f1f1f1;
			}
		&.header{
			background:@blueDark;
			.cinzel(28px);
			color:@yellow;
			td{
				font-weight:normal!important;
				}
			}
		td{
			border:1px solid @grayLight;
			padding:20px;
			vertical-align: middle;
			span.address{
				display: block;
				padding-top:10px;
				}
			&:first-child{
				.bold();
				}
			}
		}
	}
/*********PARTNERS******/
#management{
	background:@grayLight;
	.left{
		}
	.right{
		.text{
			padding-left:25px;
			border-left:4px solid @yellow;
			margin-bottom:45px;
			}
		.callout{
			.centered();
			.cinzel(28px);
			color:@blue;
			position:relative;
			padding:15px;
		
			}
		}
	}
	
.small-icon-item{
	border-bottom:2px solid #fff;
	padding:15px 10px 15px 0;
	.opensans(22px);
	.italic();
	color:@blue;
	.text{
		display:inline-block;
		vertical-align: middle;
		}
	span{
		.opensans(45px);
		.bold();
		vertical-align: bottom;
		line-height:45px;
		font-style: normal;
		}
	img.icon{
		.inline-block();
		vertical-align: middle;
		width:80%;
		max-width:80px;
		margin-right:20px;
		}
	}

#partners-grid{
	.transition();
	
	}

.loaderimage{
		display:none;
		position:absolute;
		top:170px;
		left:50%;
		margin-left:-20px;
		padding:20px 0 0 0;
		img{
		width:80px;
		height:auto;
		.inline-block();
		&.down{
			top:60px;
			}
		}
		&.partners{
			top:80px;
			}
		}
		
#partner-item-wrap{
	margin-top:40px;
	margin-bottom:50px;
	.transition()!important;
	h4{
		color:@blue;
		.cinzel(24px);
		}
	}

#emeriti{
	border-top:2px solid @grayLight;
	padding-top:50px;
	}

.partner-item{
	width:180px;
	.inline-block();
	margin:10px 10px 20px 10px;
	position:relative;
	.scale(1);
	.transition();
	opacity:0;
	span{display:block;}
	img{
		display:inline-block;
		width:160px;
		height:auto;
		margin-bottom:5px;
		border:2px solid @grayLight;
		}
	.opensans(15px);
	color:@blue;
	.uppercase();
	.bold();
	.centered();
	&:hover{
		.scale(1.1)!important;
		color:@yellow;
		img{
			border:2px solid @yellow;
			}
		}
	&.on{
		opacity:1!important;
		}
	}
	
.filter-form{
	select{width:100%;}
	.checkbox-wrap{
		clear:both;
		padding-top:30px!important;
		padding-bottom: 10px!important;
		margin-top:15px;
		label{
			.cinzel(18px);
			color:@blueDark;
			.inline-block();
			width:100%;
			max-width:400px;
			padding:0 10px 0 10px;
			}
		}
	}
	
#filter-forms{
	position:relative;
	margin-bottom:45px;
	}
	
#partner-details{
	padding-right:35px;
	text-align:left;
	img{
		border:2px solid @grayLight;
		margin-bottom:20px;
		max-width:100%;
		height:auto;
		}
	.widget{
		h5.widget-title{
			text-align: left;
			}
		}
	ul{
		li{
			padding-left:20px;
			margin-bottom:8px;
			.bold();
		color:@blue;
		position:relative;
		text-align:left;
			i{color:@yellow;position:absolute;top:3px;
		left:0;}
		}
		&.social-details{
			margin-bottom:15px;
			li{
				padding-left:25px;
				i{font-size:20px!important;}
				i.fa-caret-right{display:none;}
				a{
					color:@blueDark;
					&:hover{
						color:@yellow;
						}
					}
			}
			}
		}
	}
	
.advisor-item{
	padding:30px!important;
	background:#f1f1f1;
	border:2px solid @grayLight;
	h6{
		.opensans(28px);
		.bold();
		.uppercase();
		color:@blue;
		}
	
	}
	
.directorship-item{
		padding:30px!important;
	background:#f1f1f1;
	border:2px solid @grayLight;
	margin-bottom:30px;
	h5{
		.opensans(30px);
		color:@blueDark;
		.uppercase();
		.bold();
		margin-bottom:30px;
		}
	h6{
		.cinzel(22px);
		color:@blue;
		margin-bottom:0;
		line-height:1.2;
		}
	.directorships-box-item{
		padding:0 0 20px 0;
		border-bottom:1px solid @yellow;
		margin-bottom:20px;
		}
	.partner{
		.centered();
		.name{
		display:block;
		padding:0px 0 20px 0;
		.opensans(16px);
		line-height:1.2;
		.bold();
		.uppercase();
		color:@blue;

			}
		}
	.text{
		.time{
		display:block;
		padding:5px 0 5px 0;
		.opensans(16px);
		line-height:1.2;
		.bold();
		.uppercase();
		color:@blue;

			}
		img{
			padding:10px 0 10px 0;
			display:block;
			width:auto;
			max-width:200px!important;
			margin-bottom:15px;
			}
		}
	}

/**********EVENTS***********/

.event-item{
	border-bottom:2px solid @grayLight;
	margin-bottom:30px;
	padding-bottom:30px;
	padding-left:100px;
	position:relative;
	
	h6{
		.cinzel(20px);
		color:@blueDark;
		}
	.date{
		.centered();
		position:absolute;
		.inline-block();
		width:80px;
		height:80px;
		.rounded-corners(50%);
		border:8px solid @grayLight;
		top:0;
		left:0;
		span{
			display:block;
			line-height:1;
			&.month{
				color:@blueDark;
				.uppercase();
				.opensans(16px);
				.bold();
				padding:15px 0 2px 0;
				}
			&.day{
				color:@yellow;
				.uppercase();
				.opensans(20px);
				.bold();
				}
			}
		}
	}
	
/********MISC TEMPLATES******/

#involvement{
	background:@grayLight;
	ul{
		li{
			color:@blue;
			margin-bottom:18px;
			a{
				color:@blue;
				.bold();
				&:hover{
					color:@yellow;
					}
				}
			}
		}
	}
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .cinzel(22px);
    position:relative;
    padding:0 60px 0 60px;
    .centered();
    color:@blueDark;
    &.left{
        width:100%;
        max-width:320px;
        float:left;
        margin:0 20px 15px 0;
        padding:0 20px 0 0;
        border-right:3px solid @yellow;
        text-align:right;
        }
    &.right{
        width:100%;
        max-width:320px;
        float:right;
        padding:0 0 0 20px;
        border-left:3px solid @yellow;
        margin:0 0 15px 20px;
        text-align:left;
        }
    &.center{
        width:100%;
        border-top:3px solid @yellow;
        border-bottom:3px solid @yellow;
        padding:15px 20px 15px 20px;
        margin:10px 0 25px 0;
   
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }
    
.toggler{
		margin-bottom:35px;
	.trigger{
		.cinzel(22px);
		color:#fff;
		height:70px;
		line-height:70px;
		.border-radiuses(0,0,35px,35px);
		background:@blueDark;
		position:relative;
		padding-left:100px;
	
		i{
			.inline-block();
			color:@yellow;
			margin-left:15px;
			vertical-align:middle;
			}
		span.number{
			width:60px;
			height:60px;
			.transition();
			color:@yellow;
			.opensans(42px);
			line-height:60px;
			.centered();
			.bold();
			background:#fff;
			.rounded-corners(50%);
			.inline-block();
			position:absolute;
			top:5px;
			left:5px;
			}
		
		.transition();
		&:hover, &.active{
			cursor:pointer;
			background:@yellow;
			color:@blueDark;
			i{
				color:@blue;
				}
			span.number{
				color:@blueDark;
				}
			}
		}
	.toggle_container{
		height:0;
		overflow:hidden;
		padding:0 0 0 80px;
		.block{
			background:#fff;
			padding:20px;
			line-height:1.5;
			}
		}
	
	&.page{
		.trigger{
			padding-left:30px;
			.rounded-corners(35px);
			}
		.toggle_container{
			padding-left: 0;
			}
		}
	}
/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
   padding:0;
		
    	position:absolute;
    	background:transparent;
    	top:0;
    	left:0;
    	z-index:9999;
    	width:100%;
		height:auto;
		
    .logo-wrap{
    	padding-top:58px;
    	img{
    	
    		}
    	}
}

.top-box{
	padding-top:10px;
	text-align:right;
	color:#fff;
	.gform_wrapper{
		padding:0!important;
		margin:0!important;
		position:relative;
		.inline-block();
		width:30%!important;
		vertical-align:middle;
		margin-right:10px!important;
		 ::-webkit-input-placeholder {
                       color: #fff!important;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                       color: #fff!important;
                    }
                    
                    ::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff!important;
                    }
                    
                    :-ms-input-placeholder {  
                       color: #fff!important;
                    }
		ul.gform_fields{
			li{
				margin-top:0!important;
				padding:0!important;
				}
			}
		label{display:none!important;}
		input[type=text]{
			border:1px solid rgba(119, 140, 153, 1.000)!important;
			background:transparent;
			color:#fff!important;
			.opensans(10px)!important;
			.uppercase();
			padding:5px 25px 5px 5px!important;
			.transition();
			outline:none!important;
			&:focus{
				background:rgba(7, 42, 67, 0.8);
				}
			}
		.gform_footer{
			.inline-block();
			width:auto!important;
			position:absolute;
			top:9px;
			right:4px;
			button{
				background:transparent;
				border:none;
				font-size:14px;
				color:#fff;
				.transition();
				.scale(1);
				&:hover{
					color:@yellow;
					cursor:pointer;
					.scale(1.1);
					}
				}
			}
		}
	#menu-social-navigation{
		.inline-block();
		vertical-align:middle;
		li{
			
				font-size:16px;
				.transition();
				.scale(1);
				&:hover{
					color:@yellow;
					.scale(1.1);
					}
				}
		
		}
	#menu-top-navigation{
		li{
			margin-left:32px;
			a{
				color:#fff;
				.opensans(13px);
				.uppercase();
				&:hover{
					color:@yellow;
					}
				}
			&:before{
				.inline-block();
				margin-right:6px;
				}
			}
		}
	} 

.nav-wrap{
	text-align:right;
	}
#menu-main-navigation{
    list-style:none;
    padding:0;
    >li{
        .inline-block();
        margin-right:2px;
        position:relative;
        &:hover>a, &.current_page_item>a, &.current-page-ancestor>a{color:@yellow!important;}
      
        &:last-child{
        	a{padding-right:0;}
        	i.fa-circle{display:none!important;}
        	}
        i.fa-circle{
        	font-size:5px;
        	color:#fff;
        	.inline-block();
        	position:absolute;
        	top:22px;
        	right:-5px;
        	}
        >a{
            .cinzel(20px);
            letter-spacing:.02em;
            display:block;
            padding:15px;
            color:#fff;
            }
        a:hover{
            color:@accent;
            }
        
        position:relative;
        >ul.sub-menu{
        	&:before{
        		content:'';
        		width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 10px 10px 10px;
				border-color: transparent transparent #f6c530 transparent;
				position:absolute;
				left:50%;
				margin-left:-10px;
				top:-15px;
        		}
        	width:260px;
        	background:rgba(255,255,255,0.95);
        	border-top:6px solid @yellow;
        	position:absolute;
        	top:100%;
        	left:50%;
        	z-index:9999;
        	margin-left:-130px;
        	display:none;
        	.box-shadow-custom(0px,0px,3px,rgba(0,0,0,0.3));
        	>li{
        		display:block;
        		a{
        			display:block;
        			text-align:left;
        			.cinzel(15px);
        			color:@blueDark;
        			border:2px solid transparent;
        			.transition();
        			padding:15px;
        			i{
        				color:@yellow;
        				margin-right:10px;
        				}
        			&:hover{
        				background:rgba(0,0,0,0.05);
        				padding:15px 5px 15px 25px;
        				border:2px solid #fff;
        				i{color:@blueDark;}
        				}
        			}
        		}
        	}
        }
    }
 
.social-menu{
    .inline-block();
    margin:0;
    padding:0;
    li{
        .inline-block();
        color:#fff;
        padding:0;
        margin:0;
        position:relative;
        margin:0 3px 0 3px;
        font-size:22px;
				.transition();
				.scale(1);
				&:hover{
					color:@yellow;
					.scale(1.1);
					}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   	
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
/***********BUTTONS***********/

a.button, #main-content button.submit{
    .cinzel(18px);
    display:inline-block;
    background:#fff!important;
    font-weight:400!important;
    padding:8px 20px 8px 20px;
    color:@blueDark!important;
    position:relative;
    border:1px solid @blueDark;
    .centered();
    line-height:18px!important;
    .transition();
    .scale(1);
    i{
    	color:@yellow;
    	margin-left:12px;
    	}
    &:hover{
        background:@blueDark!important;
        .scale(1.05);
        color:#fff!important;
        cursor:pointer;
        }
    &.small{
    	font-size:14px;
    	}
    &.white{
    	background:transparent!important;
    	color:#fff!important;
    	border:1px solid #fff!important;
    	i{color:#fff!important;}
    	&:hover{
    		background:@blue!important;
    		}
    	}
    &.transparent{
    	background:transparent!important;
    	&:hover{
    	background:@blueDark!important;
 
    		
    		}
    	}
    &.dark{
    	background:@blueDark!important;
    	color:#fff!important;
    	&:hover{
    	background:@blueDark!important;
		color:@yellow!important;
    		
    		}
    	}
    }   
    
  a.block-button{
  	.inline-block();
  	background:#fff;
  	color:@blueDark!important;
  	.cinzel(26px);
  	.centered();
  	min-width:50%;
  	padding:10px 15px 10px 15px;
  	font-weight:normal!important;
  	border:1px solid @blueDark;
  	.scale(1);
  	.transition();
  	&:hover{
  		color:@yellow!important;
  		.scale(1.05);
  		background:@blueDark;
  		}
  	}

/**********ITEMS and WIDGETS**************/


.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:1px solid @content;
}

.widget{
	color:@content;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:2px solid @grayLight;
    font-size:15px;
    color:@content;
    line-height:1.6;
    .centered();
    a{
    	color:@blue;
    	&:hover{
    		color:@yellow;
    		}
    	}
    img.widget-icon{
    	width:60%;
    	max-width:120px;
    	.inline-block();
    	margin-bottom:15px;
    	}
    &.left{
    	text-align:left;
    	}
    h5.widget-title{
	    .cinzel(29px);
	    color:@blueDark;
	    line-height:1.2;
	    margin-bottom:15px;
    }
    &#subscribe{
    	.gform_wrapper{
		padding:0!important;
		margin:0!important;
		position:relative;
		.inline-block();
		width:80%!important;
		vertical-align:middle;
		margin-right:10px!important;
		 
		ul.gform_fields{
			li{
				margin-top:0!important;
				padding:0!important;
				}
			}
		label{display:none!important;}
		input[type=text]{
			border:1px solid @blueDark!important;
			background:transparent;
			color:@blueDark!important;
			.opensans(10px)!important;
			.uppercase();
			padding:5px 25px 5px 5px!important;
			.transition();
			outline:none!important;
			&::-webkit-input-placeholder {
                       color: #838e97!important;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                       color: #838e97!important;
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: #838e97!important;
                    }
                    
                    &:-ms-input-placeholder {  
                       color: #838e97!important;
                    }
			&:focus{
				background:rgba(7, 42, 67, 0.8);
				color:#fff!important;
				&::-webkit-input-placeholder {
                       color: #fff!important;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                       color: #fff!important;
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff!important;
                    }
                    
                    &:-ms-input-placeholder {  
                       color: #fff!important;
                    }
				}
			}
		.gform_footer{
			.inline-block();
			width:auto!important;
			position:absolute;
			top:9px;
			right:4px;
			button{
				background:transparent;
				border:none;
				font-size:14px;
				color:@yellow;
				.transition();
				.scale(1);
				&:hover{
					color:@blueDark;
					cursor:pointer;
					.scale(1.1);
					}
				}
			}
		}
    	}
}

ul.widget-list{
	li{
		margin:0;
		padding:0;
		a{
			display:block;
			padding:10px;
			text-align:left;
			.scale(1);
			.transition(all linear 140ms);
			.opensans(18px);
			.bold();
			color:@blueDark;
			i{
				color:@yellow;
				margin-right:10px;
				}
			&:hover{
				.scale(1.05);
				background:@blueDark;
				color:@yellow;
				i{
					color:#fff;
					}
				}
			}
		}
	}

.widget-blog-item{
	.image{
		width:100%;
		height:150px;
		border:4px solid @grayLight;
		margin-bottom:25px;
		}
	a.readmore{
		display:none;
		}
	h6{
		.cinzel(20px);
		color:@blueDark;
		margin-bottom:15px;
		a{
			color:@blueDark;
			&:hover{
				color:@yellow;
				}
			}
		}
	}
 
.widget-testimonial-item{
	cite{
		font-style:normal;
		.uppercase();
		.opensans(18px);
		span{display:block;.bold();}
		}
	}
/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

br.clear{
	display:block;
	width:0;
	height:0;
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }